import { Vue, Options } from 'vue-class-component'
import { useRoute } from 'vue-router'
import { Watch } from 'vue-property-decorator'
import MembersDataService from '@/services/MembersDataService'
import UsersDataService from '@/services/UsersDataService'
import ClubsDataService from '@/services/ClubsDataService'
import OpeningHoursDataService from '@/services/OpeningHoursDataService'
import ByPostDataService from '@/services/ByPostDataService'
import MediasDataService from '@/services/MediasDataService'
import SysMember from '@/types/SysMember'
import SysClub, { SysClubOpeningHours, WheelchairFriendlyType, clubOpeningDaysFriendlyType } from '@/types/SysClub'
import SysOpeningHours, { openingDaysFriendlyType } from '@/types/SysOpeningHours'
import ByPost from '@/types/ByPost'
import BaseFunctions, { defaultAdminBackendGroupId, defaultClubManagerGroupId, defaultMissingImgURL, LogoObj, mediaBaseURL } from '@/components/Utility/Base'
import CommonFunctions from '@/components/Utility/Common'
import { Quill, QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import AuthService from '@/services/AuthService'
import ExternalServicesDataService from '@/services/ExternalServicesDataService'
import { Loader } from '@googlemaps/js-api-loader'
import SysExternalServices from '@/types/SysExternalServices'

type clubDataType = { clubID: number; clubManagerID: number; clubFacebook: string; clubWebpage: string; clubStatus: boolean; clubLogo: string; clubName: string; clubAddress: string; clubPostalNumber: string; clubCity: string; clubRegion: string; clubTelephone: string; clubEmail: string; clubDescription: string; originalClubDescription: string; clubLanes: number; homeMatches: number; clubEstablishedDate: string; wheelchairFriendly: boolean; clubFunds: number, openingHours: SysClubOpeningHours[]; clubLicenseExpires: string; }
type dataReturnType = { clubData: any; uploadLogo: any; quillToolbar: any; error: any; }
type clubAddressDataType = { clubAddress: string, clubPostalNumber: string, clubCity: string }

@Options({
  components: {
    QuillEditor
  }
})
export default class editClub extends Vue {
  private memberID = this.parseURL()
  error: any = null
  private tempMember: SysMember[] = []
  private tempClubMembers: SysMember[] = []
  clubData: clubDataType = { clubID: 0, clubManagerID: 0, clubFacebook: '', clubWebpage: '', clubStatus: true, clubLogo: '', clubName: '', clubAddress: '', clubPostalNumber: '', clubCity: '', clubRegion: '', clubTelephone: '', clubEmail: '', clubDescription: '', originalClubDescription: '', clubEstablishedDate: '', clubLanes: 0, clubFunds: 0, wheelchairFriendly: false, homeMatches: 0, openingHours: [], clubLicenseExpires: '' }
  private editOpeningHoursModal = false
  private openingHoursData = { day: '', from: '', to: '', comment: '' }
  private editOpeningHoursDayOptions = Object.values(openingDaysFriendlyType).filter(value => typeof value === 'string').filter(value => !value.toString().includes('oe'))
  private currentlyEditingOpeningHours = 0
  private createOpeningHoursModal = false
  private editClubModal = false
  private cityPostalNumberInputFocus = 2 // Used to track which watch-tied input fields has the current input focus. Value 1 = city input field is in focus, value 2 = postal code input field is in focus
  private inhibitUpdateCityInfo = false
  private tempCityPostal: ByPost[] = []
  private currentCityPostalNumberID = 0
  private readonlyDescription = true
  private editClubLogoModal = false
  // private uploadLogo: any[] = []
  private uploadLogo: any = []
  private clubManageValue = { name: '', id: 0 }
  private clubManagerOptions: { name: string, id: number}[] = []
  private changeClubManager = false
  private externalservices = {} as SysExternalServices
  clubAddressData = {} as clubAddressDataType
  toolbarOptions: any[] = [
    ['bold', 'italic', 'underline'],
    [],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [],
    [{ align: [] }]
  ]

  readonly name : string = 'editClubInformation'
  $Message: any
  data (): dataReturnType {
    return {
      error: this.error,
      clubData: this.clubData,
      uploadLogo: this.uploadLogo,
      quillToolbar: {
        modules: {
          toolbar: {
            container: this.toolbarOptions
          }
        }
      }
    }
  }

  declare $refs: {
    clubDescription: typeof QuillEditor
  }

  @Watch('editClubLogoModal')
  onLogoModalChange (newVal: boolean) : void {
    if (!newVal) {
      this.uploadLogo = []
    }
  }

  @Watch('uploadLogo')
  onNewLogoUpload () {
    console.log(this.uploadLogo)

    // Uploads the new logo to media server
    // if (this.uploadLogo !== undefined && this.uploadLogo.length > 0) {
    if (this.uploadLogo !== undefined && JSON.stringify(<File> this.uploadLogo.size) !== null && Number((<File> this.uploadLogo).size) > 0) {
      // const fileToUpload = <File> this.uploadLogo[0]
      const fileToUpload = <File> this.uploadLogo
      const fileExtension = (fileToUpload === undefined ? '' : fileToUpload.name.split('?')[0].split('.').pop())
      const newFileName = 'clublogo-' + this.clubData.clubID.toString() + '-' + this.convertToSlug(this.clubData.clubName) + '.' + fileExtension
      const fd: FormData = new FormData()

      // fd.append('files', this.uploadLogo[0], newFileName)
      fd.append('files', this.uploadLogo, newFileName)

      MediasDataService.upload(fd)
        .then((response) => {
          console.log(response.data)
          const updateClubLogo = {
            klubber_logo: response.data
          }

          // Deletes the old logo, if one is already present
          if (this.clubData.clubLogo.length > 0) {
            const oldLogo: any = this.clubData.clubLogo
            // console.log(oldLogo)

            MediasDataService.delete(oldLogo[0].id)
              .then((response) => {
                // console.log(response.data)
              })
              .catch((err) => {
                console.error(err)
              })
          }

          // Sets the new logo
          ClubsDataService.update(this.clubData.clubID.toString(), updateClubLogo)
            .then((response) => {
              // console.log(response.data)
              this.retrieveClubInformation()
            })
            .catch((err) => {
              console.error(err)
            })

          this.uploadLogo = []
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  @Watch('editOpeningHoursModal')
  onEditOpeningHoursClose (newVal : boolean) : void {
    if (newVal === false) {
      this.openingHoursData = { day: '', from: '', to: '', comment: '' }
      this.currentlyEditingOpeningHours = 0
    }
  }

  @Watch('createOpeningHoursModal')
  onCreateOpeeningHoursClose (newVal : boolean) : void {
    if (newVal === false) {
      this.openingHoursData = { day: '', from: '', to: '', comment: '' }
    }
  }

  @Watch('editClubModal')
  onEditClubClose (newVal : boolean) : void {
    if (newVal === false) {
      this.retrieveClubInformation()
    }
  }

  public updateClubManager () {
    if (this.clubManageValue.id === this.clubData.clubManagerID) {
      return this.$Message.warning({ text: this.clubManageValue.name + ' er allerede klubansvarlig' })
    }
    const removeUserPermissionsFromOldManager = async (userId: number, updatePermissions: any) => {
      await UsersDataService.update(userId.toString(), updatePermissions)
        .then((response) => {
          // console.log(response.statusText)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const addUserPermissionsToNewManager = async (userId: number, updatePermissions: any) => {
      await UsersDataService.update(userId.toString(), updatePermissions)
        .then((response) => {
          // console.log(response.statusText)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const addClubManagerIdToClub = async (clubId: number, updateClubData: any) => {
      await ClubsDataService.update(clubId.toString(), updateClubData)
        .then((response) => {
          // console.log(response.statusText)
        })
        .catch((err) => {
          console.error((err))
        })
    }

    let newManager = {} as SysMember

    const tempNewManger = this.tempClubMembers.find(value => value.id === this.clubManageValue.id)
    if (tempNewManger === undefined) {
      return
    }
    newManager = tempNewManger
    const promises = []
    const oldPermissions = {
      usrgroup: defaultAdminBackendGroupId
    }
    promises.push(removeUserPermissionsFromOldManager(Number(this.tempMember[0].user_id.id), oldPermissions))

    const newPermissions = {
      usrgroup: defaultClubManagerGroupId
    }
    promises.push(addUserPermissionsToNewManager(Number(newManager.user_id.id), newPermissions))

    const updateClub = {
      klubber_ansvarlig_id: Number(newManager.id)
    }
    promises.push(addClubManagerIdToClub(this.clubData.clubID, updateClub))

    Promise.all(promises)
      .then(() => {
        this.changeClubManager = false
        this.retrieveClubInformation()
      })
  }

  public convertToSlug (text : string) : string {
    return CommonFunctions.slugify(text)
  }

  public updateDescription () : void {
    const updateDescrtiption = {
      klubber_klubbeskrivelse: this.clubData.clubDescription
    }

    ClubsDataService.update(this.clubData.clubID.toString(), updateDescrtiption)
      .then((response) => {
        this.retrieveClubInformation()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // public editDescription () {
  //   let tempDescription = ''
  //   if (this.readonlyDescription) {
  //     this.readonlyDescription = false
  //     tempDescription = this.clubData.clubDescription
  //     console.log(tempDescription)
  //   } else {
  //     if (tempDescription === this.clubData.clubDescription) {
  //       this.readonlyDescription = true
  //     } else {
  //       const updateDescription = {
  //         klubber_klubbeskrivelse: this.clubData.clubDescription
  //       }

  //       ClubsDataService.update(this.clubData.clubID.toString(), updateDescription)
  //         .then((response) => {
  //           console.log(response.statusText)
  //           this.retrieveClubInformation()
  //           this.readonlyDescription = true
  //         })
  //         .catch((err) => {
  //           console.error(err)
  //         })
  //     }
  //   }
  // }

  public async updateClubInfo () {
    if (this.clubData.clubAddress === '') {
      return this.$Message.danger({ text: 'Vej og hus nr. mangler' })
    }
    if (this.clubData.clubPostalNumber.length !== 4) {
      return this.$Message.danger({ text: 'Postnr. mangler' })
    }
    if (Number.isNaN(this.clubData.clubTelephone) || this.clubData.clubTelephone.toString().length !== 8) {
      return this.$Message.danger({ text: 'Telefonnr. mangler' })
    }
    if (!this.checkEmail(this.clubData.clubEmail)) {
      return this.$Message.danger({ text: 'Email mangler' })
    }
    if (this.clubData.clubWebpage !== '' && !this.testValidWebpage(this.clubData.clubWebpage)) {
      return this.$Message.danger({ text: 'Der mangler en gyldig hjemmeside' })
    }
    if (this.clubData.clubFacebook !== '' && !this.testValidFacebook(this.clubData.clubFacebook)) {
      return this.$Message.danger({ text: 'Der mangler en gyldig facebook side' })
    }
    if (this.clubData.clubLanes < 0) {
      return this.$Message.danger({ text: 'Angiv venligst et gyldig antal baner' })
    }
    if (this.clubData.homeMatches < 0) {
      return this.$Message.danger({ text: 'Angiv venligst et gyldigt antal hjemmekampe' })
    }

    const updateClubInfo = {
      klubber_vejnavn: this.clubData.clubAddress,
      bypost_id: this.currentCityPostalNumberID,
      klubber_telefonnummer: Number(this.clubData.clubTelephone),
      klubber_email: this.clubData.clubEmail,
      klubber_baner: this.clubData.clubLanes,
      klubber_hjemmekampe: this.clubData.homeMatches,
      klubber_koerestolsvenlig: (this.clubData.wheelchairFriendly ? WheelchairFriendlyType.Ja : WheelchairFriendlyType.Nej),
      klubber_hjemmeside: this.clubData.clubWebpage, // Should it be null when clubWebpage is = ''
      klubber_facebook: this.clubData.clubFacebook
    }

    // Update the Latitude and Longitude
    if (this.clubAddressData?.clubAddress !== this.clubData.clubAddress || this.clubAddressData?.clubCity !== this.clubData.clubCity || this.clubAddressData?.clubPostalNumber !== this.clubData.clubPostalNumber) {
      await this.retrieveExternalServices()
      const loader = new Loader({
        apiKey: BaseFunctions.defDercpyt(this.externalservices.gms_key),
        version: 'quarterly',
        libraries: ['places']
      })
      const geocodeAddress = (this.clubData.clubAddress.trim() + ',+' + this.clubData.clubCity.trim() + ' ' + this.clubData.clubPostalNumber.trim() + ',+Danmark')
      await loader.load().then(async () => {
        const geocoderClass = new google.maps.Geocoder()
        console.log('Address: ' + geocodeAddress)
        const geocodeDataProperties = { address: geocodeAddress } as google.maps.GeocoderRequest
        const promise = geocoderClass.geocode(geocodeDataProperties)
        let geocodeGeometryLocationLat = 0
        let geocodeGeometryLocationLng = 0
        await promise
          .then((response) => {
            geocodeGeometryLocationLat = response.results[0].geometry.location.lat()
            geocodeGeometryLocationLng = response.results[0].geometry.location.lng();
            (updateClubInfo as any).klubber_longitude = geocodeGeometryLocationLng;
            (updateClubInfo as any).klubber_latitude = geocodeGeometryLocationLat
          })
          .catch((error) => {
            console.log(error)
          })
      })
    }

    ClubsDataService.update(this.clubData.clubID.toString(), updateClubInfo)
      .then((response) => {
        // console.log(response.statusText)
        this.editClubModal = false
        this.retrieveClubInformation()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public testValidWebpage (v: string) : boolean {
    if (v.includes('http://') || v.includes('https://')) {
      return true
    }
    return false
  }

  public testValidFacebook (v: string) : boolean {
    if (v.includes('https://da-dk.facebook.com/') || v.includes('https://www.facebook.com/') || v.includes('https://facebook.com')) {
      return true
    }
    return false
  }

  public createOpeningHours () {
    if (this.openingHoursData.day === '' || this.openingHoursData.from === '' || this.openingHoursData.to === '') {
      return this.$Message.danger({ text: 'Fejl:  Et af felterne mangler' })
    }
    const createOpeningHours = {
      aabningstider_dag: this.parseEnum(this.openingHoursData.day),
      aabningstider_aaben: (this.openingHoursData.from.length < 12 ? this.openingHoursData.from + ':00.000' : this.openingHoursData.from),
      aabningstider_luk: (this.openingHoursData.to.length < 12 ? this.openingHoursData.to + ':00.000' : this.openingHoursData.to),
      aabningstider_kommentar: (this.openingHoursData.comment === '' ? null : this.openingHoursData.comment),
      aabningstider_status: 1,
      klubber_id: this.clubData.clubID
    }

    OpeningHoursDataService.create(createOpeningHours)
      .then((response) => {
        // console.log(response.statusText)
        this.createOpeningHoursModal = false
        this.$Message.success({ text: 'Åbningstiden er blevet tilføjet' })
        this.retrieveClubInformation()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public deleteOpeningHours (id: number) : void {
    OpeningHoursDataService.delete(id.toString())
      .then((response) => {
        // console.log(response.statusText)
        this.$Message.success({ text: 'Åbningstiden er blevet slettet' })
        this.retrieveClubInformation()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public updateOpeningHours () {
    if (this.openingHoursData.from === this.openingHoursData.to) {
      return this.$Message({ text: 'Fejl: Åbne og lukke tider er ens' })
    }
    const updateOpeningHour = {
      aabningstider_dag: this.parseEnum(this.openingHoursData.day),
      aabningstider_aaben: (this.openingHoursData.from.length < 12 ? this.openingHoursData.from + ':00.000' : this.openingHoursData.from),
      aabningstider_luk: (this.openingHoursData.to.length < 12 ? this.openingHoursData.to + ':00.000' : this.openingHoursData.to),
      aabningstider_kommentar: (this.openingHoursData.comment === '' ? null : this.openingHoursData.comment),
      aabningstider_status: 1
    }

    OpeningHoursDataService.update(this.currentlyEditingOpeningHours.toString(), updateOpeningHour)
      .then((response) => {
        // console.log(response.statusText)
        this.editOpeningHoursModal = false
        this.$Message.success({ text: 'Åbningstiden er blevet opdateret' })
        this.retrieveClubInformation()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public editOpeningHours (id: number) {
    this.editOpeningHoursModal = true
    let tempOpeningHours = {} as SysClubOpeningHours
    const temp = this.clubData.openingHours.find(value => value.id === id)
    this.currentlyEditingOpeningHours = id

    if (temp === undefined) {
      return
    }
    tempOpeningHours = temp

    this.openingHoursData = {
      day: this.convertOe(tempOpeningHours.aabningstider_dag),
      from: tempOpeningHours.aabningstider_aaben,
      to: tempOpeningHours.aabningstider_luk,
      comment: tempOpeningHours.aabningstider_kommentar
    }
  }

  public parseEnum (typeString: any) : openingDaysFriendlyType {
    return <any>openingDaysFriendlyType[typeString]
  }

  public convertOe (enumObj: clubOpeningDaysFriendlyType) : string {
    if (enumObj.toString() === 'Loerdag') {
      return 'Lørdag'
    } else if (enumObj.toString() === 'Soendag') {
      return 'Søndag'
    }
    return enumObj.toString()
  }

  expirationDate (dateString: string | null) : string {
    return (dateString === null ? '01/01/70' : this.toDanishDateString(dateString))
  }

  public checkEmail (email: string) : boolean {
    return BaseFunctions.isEmail(email)
  }

  public checkStrongPassword (password: string) : boolean {
    return CommonFunctions.strongPasswordTest(password)
  }

  public toDanishDateString (dateString: string) : string {
    return new Date(dateString).toLocaleDateString('da-DK', { day: '2-digit', month: '2-digit', year: '2-digit' }).replaceAll('.', '/')
  }

  public parseURL () : number {
    let retVal = 0
    const route = useRoute()
    const memberID = route.params.userSlug.toString().split('-')[0]
    if (Number(memberID) !== undefined) {
      retVal = Number(memberID)
    }
    return retVal
  }

  public extractLogoURLFromClubLogoData (clubLogoData: any) : string {
    let logoURL = defaultMissingImgURL

    if (clubLogoData !== null) {
      const logoObj = BaseFunctions.setLogoMediaObjFromObj(<any> clubLogoData) as LogoObj[]
      logoURL = logoObj[0] === undefined || logoObj[0].url === null ? logoURL : mediaBaseURL + logoObj[0].url
    }

    return logoURL
  }

  public retrieveClubInformation () {
    // console.log('[retrieveClubInformation()] DONE.')

    // Changed to use the userId from loacalStorage
    const userString = localStorage.getItem('user')?.toString()
    const userDataObject = (userString !== undefined && userString !== null ? JSON.parse(userString) : null)

    if (userDataObject === null || userDataObject.id === 1) {
      // console.log('ERROR fetching memberData')
      CommonFunctions.redirectLogin()

      return
    }

    let tempClub = {} as SysClub
    MembersDataService.getAll('', null, `user_id.id=${userDataObject.id}`)
      .then((response) => {
        this.tempMember = response.data

        if (this.tempMember.length !== 1) {
          this.$Message.danger({ text: 'Fejl: Noget gik galt' })

          return
        }

        // A rudementary check, if the memberId from the URL, does not match the memberId retrieved via the userId, logout and go to login page.
        if (this.memberID !== Number(this.tempMember[0].id)) {
          // console.log('[retrieveClubInformation()] memberId = ' + this.memberID.toString() + ' =/= ' + Number(this.tempMember[0].id))
          AuthService.logout()
          CommonFunctions.redirectLogin()

          return
        }

        // usrgroup 3 er klubansvarlig
        if (this.tempMember[0].user_id.usrgroup !== 3) {
          this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })

          return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
        }

        ClubsDataService.get(Number(this.tempMember[0].klubber_id.id).toString())
          .then((response) => {
            tempClub = response.data

            // if (this.tempMember[0].klubber_id.id !== tempClub.id || this.tempMember[0].id !== tempClub.klubber_ansvarlig_id.id) {
            if (this.tempMember[0].klubber_id.id !== tempClub.id || (this.tempMember[0].id !== tempClub.klubber_ansvarlig_id.id && Number(this.tempMember[0].id) > 9)) {
              this.$Message.danger({ text: 'Du har ikke rettigheder til at være her' })
              // return this.$router.push({ name: 'Login', path: 'medlemsportal/login' })
              // AuthService.logout()
              CommonFunctions.redirectLogin()

              return
            }
            this.clubAddressData = {
              clubAddress: tempClub.klubber_vejnavn,
              clubPostalNumber: tempClub.bypost_id.bypost_postnummer.toString(),
              clubCity: tempClub.bypost_id.bypost_by
            }
            this.clubData = {
              clubID: Number(tempClub.id),
              clubManagerID: Number(tempClub.klubber_ansvarlig_id.id),
              clubFacebook: (tempClub.klubber_facebook === null ? '' : tempClub.klubber_facebook),
              clubWebpage: (tempClub.klubber_hjemmeside === null ? '' : tempClub.klubber_hjemmeside),
              clubStatus: tempClub.klubber_status,
              clubLogo: tempClub.klubber_logo, // remember can be null
              clubName: tempClub.klubber_klubnavn,
              clubAddress: tempClub.klubber_vejnavn,
              clubPostalNumber: tempClub.bypost_id.bypost_postnummer.toString(),
              clubCity: tempClub.bypost_id.bypost_by,
              clubRegion: tempClub.region_id.region_navn,
              clubTelephone: tempClub.klubber_telefonnummer,
              clubEmail: tempClub.klubber_email,
              clubDescription: tempClub.klubber_klubbeskrivelse,
              originalClubDescription: tempClub.klubber_klubbeskrivelse,
              clubEstablishedDate: tempClub.klubber_etableringsaar,
              clubLanes: tempClub.klubber_baner,
              clubFunds: tempClub.klubber_saldo,
              wheelchairFriendly: (tempClub.klubber_koerestolsvenlig === WheelchairFriendlyType.Ja),
              homeMatches: tempClub.klubber_hjemmekampe,
              openingHours: tempClub.aabningstiders_id,
              clubLicenseExpires: tempClub.klubber_kontingent_slut
            }

            if (tempClub.bypost_id !== undefined || tempClub.bypost_id !== null) {
              this.currentCityPostalNumberID = Number(tempClub.bypost_id.id)
              // console.log(this.currentCityPostalNumberID)
            }

            // console.log(this.clubData)
            this.clubManageValue = { name: this.tempMember[0].user_id.firstname + ' ' + this.tempMember[0].user_id.lastname, id: Number(this.tempMember[0].id) }
            this.getClubMembers(Number(this.tempMember[0].klubber_id.id))

            if (this.clubData.openingHours.length > 1) {
              const weekdayOrder = Object.values(clubOpeningDaysFriendlyType)
              this.clubData.openingHours = this.clubData.openingHours.sort(function (a, b) {
                return weekdayOrder.indexOf(a.aabningstider_dag) - weekdayOrder.indexOf(b.aabningstider_dag) || a.aabningstider_aaben.localeCompare(b.aabningstider_aaben)
              })
            }

            const interval = setInterval(() => {
              if (this.$refs.clubDescription) {
                clearInterval(interval)
                this.$refs.clubDescription.setContents(this.clubData.clubDescription, 'user')
              }
            }, 100)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  public async retrieveExternalServices () : Promise<void> {
    await ExternalServicesDataService.get('1')
      .then((response) => {
        this.externalservices = response.data
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public getClubMembers (clubID: number) {
    MembersDataService.getAll('', null, 'klubber_id.id=' + clubID.toString())
      .then((response) => {
        this.tempClubMembers = response.data
        for (const item of this.tempClubMembers) {
          this.clubManagerOptions.push({ name: item.user_id.firstname + ' ' + item.user_id.lastname, id: Number(item.id) })
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  // Following are code for finding and matching postal number and city
  @Watch('clubData.clubCity')
  onMemberCityChange (cityValue: string) {
    if (this.cityPostalNumberInputFocus === 1 && cityValue !== null) {
      this.updatePostalCodeInputFieldDataFromCityInputFieldData(cityValue)
    }
  }

  @Watch('clubData.clubPostalNumber')
  onMemberPostalNumberChange (postalNumberValue: number, oldFieldPostnummerValue: string) {
    if (this.cityPostalNumberInputFocus === 2 && this.inhibitUpdateCityInfo === false && !isNaN(postalNumberValue)) {
      this.updateCityInputFieldDataFromPostalCodeInputFieldData(postalNumberValue.toString())
    }
  }

  @Watch('cityPostalNumberInputFocus')
  onCityPostalNumberInputFocusChange (fieldInputFocusValue: string) {
    if (Number(fieldInputFocusValue) === 1) {
      if (!isNaN(Number(this.clubData.clubPostalNumber))) {
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.clubData.clubPostalNumber)
      }
    } else if (Number(fieldInputFocusValue) === 2) {
      if (this.clubData.clubCity !== null) {
        this.updatePostalCodeInputFieldDataFromCityInputFieldData(this.clubData.clubCity)
        this.updateCityInputFieldDataFromPostalCodeInputFieldData(this.clubData.clubPostalNumber)
      }
    }
  }

  public updatePostalCodeInputFieldDataFromCityInputFieldData (cityData: string) : void {
    if (cityData !== undefined && cityData !== null && cityData.length > 1) {
      ByPostDataService.findByCity(cityData, '', '1')
        .then((response) => {
          this.tempCityPostal = response.data
          if (this.tempCityPostal === undefined || this.tempCityPostal === null || this.tempCityPostal[0] === undefined) {
            this.currentCityPostalNumberID = 0
          } else {
            this.inhibitUpdateCityInfo = true
            this.currentCityPostalNumberID = (this.tempCityPostal[0].id !== null ? Number(this.tempCityPostal[0].id) : 0)
            // console.log('Current bypost Id : ' + this.currentCityPostalNumberID)
            this.clubData.clubPostalNumber = (this.tempCityPostal[0].id !== null ? (this.tempCityPostal[0].bypost_postnummer.toString()) : '')
            this.inhibitUpdateCityInfo = false
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  public updateCityInputFieldDataFromPostalCodeInputFieldData (postalCodeData: string) : void {
    if (postalCodeData !== undefined && postalCodeData !== null && Number(postalCodeData) > 799 && Number(postalCodeData) < 9999) {
      ByPostDataService.findByPostalnumber(Number(postalCodeData), '', '1')
        .then((response) => {
          this.tempCityPostal = response.data
          if (this.tempCityPostal === undefined || this.tempCityPostal === null || this.tempCityPostal[0] === undefined) {
            this.currentCityPostalNumberID = 0
          } else {
            this.currentCityPostalNumberID = (this.tempCityPostal[0].id !== null ? Number(this.tempCityPostal[0].id) : 0)
            // console.log('Current bypost Id : ' + this.currentCityPostalNumberID)
            this.clubData.clubCity = (this.tempCityPostal[0].id !== null ? (this.tempCityPostal[0].bypost_by) : '')
          }
        })
        .catch((err) => {
          this.error = err
          // console.log(err)
        })
    }
  }

  async mounted () : Promise<void> {
    const loggedInStatus = localStorage.getItem('status')
    const loginType = localStorage.getItem('logintype')
    const apiToken = localStorage.getItem('apitoken')
    const userdata = localStorage.getItem('user')

    if (loggedInStatus !== undefined && loggedInStatus !== null && loggedInStatus === true.toString() && loginType !== undefined && loginType !== null && loginType === true.toString() && apiToken !== undefined && apiToken !== null && apiToken.length >= 100 && userdata !== undefined && userdata !== null && !userdata.startsWith('{"id":1,')) {
      this.retrieveClubInformation()
    } else {
      CommonFunctions.redirectLogin()
    }
  }
}
